<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
       <b-img
              :src="appLogoImage"
              alt="logo"
            />
        <!-- <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          OneToko!
        </h2> -->
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-if="!signupStatus"
      >
        <b-overlay :show="isLoading" class="full-width">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Signup to OneToko! 🚀
            </b-card-title>

            <validation-observer ref="registerForm">
              <b-form class="auth-register-form mt-2">
                <b-form-group label="Full Name" label-for="register-username">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="form.name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Full Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Company Name" label-for="Company-Bame">
                  <validation-provider
                    #default="{ errors }"
                    name="company-name"
                    rules="required"
                  >
                    <b-form-input
                      id="company-name"
                      v-model="form.company_name"
                      name="register-company-name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Shopify Company Name"
                  label-for="register-shopify-company-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="shopify-company-name"
                    rules="required"
                  >
                    <b-form-input
                      id="shopify-company-name"
                      v-model="form.shopify_company_name"
                      name="register-company-name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="My Company Inc."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Email" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="form.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="test@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label-for="register-password" label="Password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="form.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  :disabled="isLoading"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <b-spinner v-if="isLoading" small />
                  Sign up
                </b-button>
              </b-form>
            </validation-observer>
            <hr />
            <div class="google-btn" @click="signUpWithGoogle()">
              <div class="google-icon-wrapper">
                <img
                  class="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              </div>
              <p class="btn-text"><b>Sign up with google</b></p>
            </div>

            <p class="text-center mt-2 ">
              <span>Already have an account?</span>
              <b-link :to="{ name: 'login' }" class="small">
                <span>&nbsp;Sign in instead</span>
              </b-link>
            </p>
          </b-col>
        </b-overlay>
      </b-col>

      <!-- <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-else
      >
        <b-overlay :show="isLoading" class="full-width">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Google Signup to OneToko! 🚀
            </b-card-title>

            <validation-observer ref="googleRegisterForm">
              <b-form class="auth-register-form mt-2">
                <b-form-group label="Full Name" label-for="register-username">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="form.name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Full Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Email" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="form.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="test@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  :disabled="isLoading"
                  type="submit"
                  @click.prevent="googleValidationForm"
                >
                  <b-spinner v-if="isLoading" small />
                  Google Sign up
                </b-button>
              </b-form>
            </validation-observer>
            <hr />
            <b-card no-body class="mb-2">
              <b-list-group>
                <b-list-group-item
                  class="cursor-pointer"
                  @click="otherSignupAccount()"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar size="42" variant="light-primary">
                        <feather-icon size="24" icon="UserIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="font-weight-600 mb-0 text-primary text-break">
                        Signup in to a different
                      </h6>
                    </b-media-body>
                  </b-media>
                </b-list-group-item>
              </b-list-group>
            </b-card>

            <p class="text-center mt-2 ">
              <span>Already have an account?</span>
              <b-link :to="{ name: 'login' }" class="small">
                <span>&nbsp;Sign in instead</span>
              </b-link>
            </p>
          </b-col>
        </b-overlay>
      </b-col> -->

      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BSpinner,
  BOverlay,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { mapGetters } from 'vuex';
import axiosIns from '@/libs/axios';
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BOverlay,
    BButton,
    BForm,
    BCardText,
    BSpinner,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        name: '',
        email: '',
        company_name: '',
        shopify_company_name: '',
      },
      isLoading: false,
      status: '',
      username: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      signupStatus: '',
    };
  },
  computed: {
    ...mapGetters({}),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

 setup(){
     const {appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },


  async created() {},
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const payload = {
            name: this.form.name,
            login: this.form.email,
            company_name: this.form.company_name,
            shopify_company_name: this.form.shopify_company_name,
            password: this.form.password,
          };
          store
            .dispatch('app-customer/signUp', payload)
            .then(() => {
              console.log('submit signup form', payload);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
              this.$router.push('/');
              this.isLoading = false;
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              });
              this.isLoading = false;
            });
        }
      });
    },

    googleValidationForm() {
      this.$refs.googleRegisterForm.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const payload = {
            name: this.form.name,
            email: this.form.email,
          };
          axiosIns
            .post('/google/signup/', payload, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(() => {
              console.log('submit signup form', payload);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
              this.$router.push({ name: 'SubscribeDashboard' });
              this.isLoading = false;
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              });
              this.isLoading = false;
            });
        }
      });
    },

    otherSignupAccount() {
      this.signupStatus = !this.signupStatus;
      console.log('click data otherSignupAccount');
    },

    signUpWithGoogle() {
      let state = new Date().getTime();
      // console.log('state data', state);
      let signupGoogleUrl = `https://accounts.google.com/o/oauth2/auth?response_type=token&client_id=330804759778-589658l0sfni2vsgh6ivmj71ctgctid5.apps.googleusercontent.com&redirect_uri=https://b.m91.link/signin/google/callback/&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&state=${state}signup`;
      console.log('signupGoogleUrl url', signupGoogleUrl);
      location.href = signupGoogleUrl;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.google-btn {
  width: 100%;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.google-btn .btn-text {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 0px;
  padding: 12px 0 0 64px;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669f2;
}
</style>
