var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),(!_vm.signupStatus)?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-overlay',{staticClass:"full-width",attrs:{"show":_vm.isLoading}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Signup to OneToko! 🚀 ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"Full Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3356810098)})],1),_c('b-form-group',{attrs:{"label":"Company Name","label-for":"Company-Bame"}},[_c('validation-provider',{attrs:{"name":"company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name","name":"register-company-name","state":errors.length > 0 ? false : null,"placeholder":"Company Name"},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3074550042)})],1),_c('b-form-group',{attrs:{"label":"Shopify Company Name","label-for":"register-shopify-company-name"}},[_c('validation-provider',{attrs:{"name":"shopify-company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"shopify-company-name","name":"register-company-name","state":errors.length > 0 ? false : null,"placeholder":"My Company Inc."},model:{value:(_vm.form.shopify_company_name),callback:function ($$v) {_vm.$set(_vm.form, "shopify_company_name", $$v)},expression:"form.shopify_company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3176379537)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"test@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1359546108)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2082297556)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.isLoading,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign up ")],1)],1)],1),_c('hr'),_c('div',{staticClass:"google-btn",on:{"click":function($event){return _vm.signUpWithGoogle()}}},[_c('div',{staticClass:"google-icon-wrapper"},[_c('img',{staticClass:"google-icon",attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"}})]),_c('p',{staticClass:"btn-text"},[_c('b',[_vm._v("Sign up with google")])])]),_c('p',{staticClass:"text-center mt-2 "},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{staticClass:"small",attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }